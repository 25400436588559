<template>
  <div id="app" :class="{ 'dark-mode': isDarkMode }">
    <button @click="toggleDarkMode">Toggle Dark Mode</button>
    <NewsList />
  </div>
</template>

<script>
import NewsList from './components/NewsList.vue';

export default {
  name: 'App',
  components: {
    NewsList
  },
  data() {
    return {
      isDarkMode: true // Set dark mode as default
    };
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    }
  }
};
</script>
<style>
body {
  background-color: #121212;
  /* Dark background */
  color: #ffffff;
  /* Light text */

}
</style>
<template>
  <div class="news-list">
    <div class="card" v-for="article in articles" :key="article.link" @click="selectArticle(article)">
      <h3>{{ article.title }}</h3>
      <img :src="article.image" :alt="article.title" />
      <p>{{ article.description }}</p>
    </div>
    <ArticleModal :article="selectedArticle" :isVisible="isModalVisible" @close="closeModal" />
  </div>
</template>

<script>
import ArticleModal from './ArticleModal.vue';
import { fetchNews } from '../newsService';

export default {
  components: {
    ArticleModal
  },
  data() {
    return {
      articles: [],
      selectedArticle: null,
      isModalVisible: false
    };
  },
  mounted() {
    this.loadArticles();
  },
  methods: {
    async loadArticles() {
      this.articles = await fetchNews();
    },
    selectArticle(article) {
      this.selectedArticle = article;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedArticle = null;
    }
  }
};
</script>
<style scoped>
/* Existing Light Mode Styles */
.news-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  background-color: #f0f0f0; /* Light background */
  color: #333; /* Dark text */
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  background-color: white;
  color: #333;
}

.card img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.card h3 {
  margin-top: 0;
}

.card p {
  font-size: 0.9em;
}

/* Dark Mode Styles */
.dark-mode .news-list {
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text */
}

.dark-mode .card {
  background-color: #1e1e1e;
  border-color: #333333;
  color: #ffffff;
}

.dark-mode .card img {
  /* If needed, adjust image styles for better visibility in dark mode */
}

/* You can add more specific dark mode styles here */
</style>

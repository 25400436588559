import axios from 'axios';

const API_ENDPOINT = 'https://api.aj-v.de/neo/golem.json'; // Replace with your actual API endpoint

export const fetchNews = async () => {
  try {
    const response = await axios.get(API_ENDPOINT);
    return response.data; // Assuming the API returns an array of articles
  } catch (error) {
    console.error('Error fetching news:', error);
    return [];
  }
};

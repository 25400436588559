<template>
    <div v-if="isVisible" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <h2>{{ article.title }}</h2>
        <img :src="article.image" :alt="article.title" />
        <div ref="articleContent" class="article-content" v-html="article.content"></div>
        <button @click="closeModal">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  
  export default {
    props: {
      article: {
        type: Object,
        required: true
      },
      isVisible: {
        type: Boolean,
        default: false
      }
    },
    setup(props, { emit }) {
      const articleContentRef = ref(null);
  
      const closeModal = () => {
        emit('close');
      };
  
      const removeInlineStyles = () => {
        if (articleContentRef.value) {
          const elements = articleContentRef.value.querySelectorAll('*');
          elements.forEach(el => el.removeAttribute('style'));
        }
      };
  
      onMounted(() => {
        removeInlineStyles();
      });
  
      return {
        articleContentRef,
        closeModal
      };
    }
  };
  </script>
  
  
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #2e2e2e;
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .article-content,
  .article-content div,
  .article-content span,
  .article-content p,
  .article-content a {
    color: #ffffff !important;
    background-color: #121212 !important;
  }
  
  .article-content h1,
  .article-content h2,
  .article-content h3,
  .article-content h4,
  .article-content h5,
  .article-content h6 {
    color: #ffffff !important;
  }

  #screen {
    color: #ffffff !important;
    background-color: #121212 !important;
  }

  #gpar1 {
    color: #ffffff !important;
    background-color: #121212 !important;
  }

  body {
    color: #ffffff !important;
    background-color: #121212 !important;
  }

  
  
  /* Additional styles as needed */
  </style>
  